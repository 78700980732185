import { ReactNode } from "react";
import { Link } from "react-router-dom";
import tw from "src/utils/tw";

interface Props {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
  to?: string;
  href?: string;
}

export function BorderCard(props: Props) {
  const className = tw(
    "block rounded-md border border-b-3 border-borderGray bg-white px-4 pb-3.5 pt-3 transition-all",
    (props.onClick || props.to || props.href) && "hover:border-[#ccc]",
    props.className
  );

  if (props.to) {
    return (
      <Link className={className} to={props.to} onClick={props.onClick}>
        {props.children}
      </Link>
    );
  }

  if (props.href) {
    return (
      <a className={className} href={props.href} target="_blank">
        {props.children}
      </a>
    );
  }

  return (
    <div className={className} onClick={props.onClick}>
      {props.children}
    </div>
  );
}
