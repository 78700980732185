import moment from "moment-timezone";
import { CreditModel } from "src/models/CreditModel";
import TagModel from "src/models/TagModel";
import UserModel from "src/models/UserModel";
import { RequiredFields } from "src/utils/constants";
import {
  AdministratorTerm,
  CustomerTerm,
  EvaluationTerm,
  PrivateTerm,
  StaffTerm,
} from "src/utils/terminology";
import {
  BetaAccessType,
  DepartmentPayload,
  Fee,
  IntegrationType,
  MeetGenderTerminology,
  Onboard,
  OrganizationCountsPayload,
  OrganizationStatus,
  PriceAdjustment,
  StripeType,
  Terminology,
  TimeType,
  departments,
  fullOrganization,
  organization,
} from "types/code-generator";
import { UsaSwimmingIntegrationPayload } from "types/usa-swimming";
import AddonModel from "./AddonModel";
import ClassModel from "./ClassModel";
import CouponModel from "./CouponModel";
import { EventModel } from "./EventModel";
import { FileModel } from "./FileModel";
import LocationModel from "./LocationModel";
import OfferingModel from "./OfferingModel";

export default class OrganizationModel {
  accountManager: UserModel;
  active: boolean;
  activeAt: Date;
  addDepartmentsDuringOnboarding: boolean = false;
  addons: AddonModel[] = [];
  address1: string;
  address2: string;
  annualPrice: number;
  betaAccess: BetaAccessType[] = [];
  chargeStartDate: Date;
  chargeAnnualStartDate: Date;
  city: string;
  classes?: ClassModel[];
  color: string;
  commissionSettings: fullOrganization["organization"]["commissionSettings"];
  contactName: string;
  counts: OrganizationCountsPayload;
  coupons: CouponModel[] = [];
  createdAt: Date;
  credits: CreditModel[];
  departments: DepartmentPayload[];
  description: string = "";
  disputeFee: number;
  email: string;
  events: EventModel[] = [];
  facebook: string;
  fees: Fee[];
  gracePeriod: number;
  gracePeriodType: TimeType;
  id: string;
  imports: FileModel[] = [];
  instagram: string;
  integrations: IntegrationType[] = [];
  lastContact: Date;
  lastContactBy: UserModel;
  lastFailedPayment: Date;
  locations: LocationModel[] = [];
  logo: FileModel;
  meetGenderTerminology: MeetGenderTerminology;
  meetTeamFullName: string;
  meetTeamShortName: string;
  meetTeamAbbreviation: string;
  meetLSC: string;
  monthlyPrice: number;
  needs: TagModel[] = [];
  nextMonthlyInvoice: Date;
  nextAnnualInvoice: Date;
  note: string;
  now: Date;
  offerings?: OfferingModel[];
  onboards: Onboard[] = [];
  owner: UserModel;
  paymentDeadline: Date;
  payouts?: any;
  phone: string;
  pipedriveUrl: string;
  planContent: string;
  priceAdjustments: PriceAdjustment[] = [];
  profiles: UserModel[];
  projectedCaptynRevenue: number;
  projectedFirstPaidRegistration: Date;
  projectedOperatingMonths: number;
  projectedRegistrations: number;
  projectedRevenue: number;
  requiredFields: RequiredFields[] = [];
  salesPerson: UserModel;
  score: number;
  solo: boolean;
  state: string;
  statementDescriptor: string;
  status: OrganizationStatus;
  storeUrl: string;
  stripe: StripeType;
  stripeAccountId: string;
  stripeAlert: boolean;
  stripeExpressAccountId: string;
  stripeExpressAccounts: organization["organization"]["stripeExpressAccounts"];
  stripeFee: number;
  stripeExpressFee: number;
  stripeTransactionFee: number;
  subdomain: string;
  tags: TagModel[] = [];
  terminology: Terminology;
  timezone: string;
  title: string = "";
  twitter: string;
  updatedAt: Date;
  usaSwimmingClubId: string;
  usaSwimmingImport: UsaSwimmingIntegrationPayload;
  usaSwimmingClubName: string;
  websiteIncluded: boolean;
  website: string;
  zip: string;

  constructor(data: OrganizationModel | any) {
    Object.assign(this, data);

    for (const key in this) {
      if (this[key] === undefined) {
        this[key] = null;
      }
    }
  }

  getUtcOffset() {
    let offset = 0;

    if (this.timezone) {
      const date = moment().tz(this.timezone);
      offset = date.utcOffset();
    }

    return offset;
  }

  getDepartment(id): departments["departments"][0] {
    return this.departments.find((d) => d.id === id);
  }

  get hasDepartments() {
    return this.departments.length > 1;
  }

  get defaultDepartment() {
    return this.departments.find((d) => d.default);
  }

  get privateTerminology() {
    return this.terminology?.private || PrivateTerm["Private Lesson"];
  }

  get evaluationTerminology() {
    return this.terminology?.evaluation || EvaluationTerm.Evaluation;
  }

  get administratorTerminology() {
    return this.terminology?.administrator || AdministratorTerm.Administrator;
  }

  get staffTerminology() {
    return this.terminology?.staff || StaffTerm.Staff;
  }

  get customerTerminology() {
    return this.terminology?.customer || CustomerTerm.Customer;
  }
}
